<template>
	<phi-page :loading="types.loading" color="#f3f3f3">
		<div slot="toolbar">
			<h1>{{ $t("noun.inbox") }}</h1>
		</div>

		<div>
			<section v-if="!!types">
				<div class="types phi-card">
					<router-link
						v-for="type in types"
						:key="type.id"
						:to="{name: 'feed', query:{type: type.id ? type.id : type.singular}}"
						class="phi-media"
					>
						<img class="phi-media-figure" :src="type.icon || defaultIcon" :alt="type.plural"  >
						<h1 class="phi-media-body" v-text="$t(type.plural)"></h1>
						<p v-show="type.unread > 0" class="phi-media-right" v-text="type.unread"></p>
					</router-link>
				</div>
			</section>

			<router-link to="compose" class="compose-button" v-if="$parent.isAllowed.compose">
				<mu-float-button icon="edit"></mu-float-button>
			</router-link>
		</div>
	</phi-page>
</template>

<script>
export default {
	name: "StateInbox",

	props: {
		personId: {
			default() {
				return this.$store.state.user.id;
			}
		}
	},

	data() {
		return {
			error: null,
			isLoading: false,
			defaultIcon: this.$store.state.url + "/icons/fa-file-text.png?color=2196F3&size=42"
		}
	},

	computed: {
		types() {
			return this.$store.getters.types;
		}
	},

	created() {
		if (typeof window !== "undefined" && typeof window.device !== 'undefined')
		{
			cordova.plugins.firebase.messaging.clearNotifications();
		}

		this.$store.dispatch("fetchPersonTypes", this.personId);
	}
}
</script>

<style lang="scss" scoped>
.phi-media {
	img.phi-media-figure {
		width: 28px;
	}
}

.compose-button {
	position: absolute;
	bottom: 20px;
	right: 20px;

	transition: transform 200ms;

	button {
		background-color: #1C89B8;
	}
}

section {
	margin-bottom: 24px;
}

.phi-media-body {
	align-self: center;

	font-size: 1.4em;
	font-weight: 400;
}

.phi-media-right {
	align-self: center;

	font-size: 12px;
	font-weight: bold;
	border-radius: 4px;
	padding: 4px 8px;

	background-color: #4c9689;
	color: #fff;
}
</style>


<style lang="scss">
/* Hide button during transitions */

.slide-enter-active,
.slide-leave-active {
	.compose-button {
		transform: scale(0,0);
	}
}

.slide-leave-active {
	.compose-button {
		display: none;
	}
}
</style>